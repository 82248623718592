.App {
  color: white;
}

.page {
  display: none;
  width: 100vw;
  height: 100vh;
}

#preloader {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preloader-text {
  font-size: 40px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.preloader-text > p {
  margin: 0;
}

#preloader-name {
  font-size: 30px;
}

.slide-up {
  animation: slide-up 1s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation-timing-function: ease;
}

.delay1 {
  animation-delay: 0.5s;
  padding: 0rem 0.9rem;
}

.delay2 {
  animation-delay: 1s;
}

@keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0); 
  }
}

.exit {
  animation: exit 1s ease-in-out;
}

@keyframes exit {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-40px);
    opacity: 0;
  }
}

#part2 {
  display: none;
  width: 150px;
  height: 50px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.block {
  width: 100%;
  height: inherit;
  background: #ffb510;
  position: absolute;
  animation: mainBlock 2s cubic-bezier(.74, .06, .4, .92) forwards;
  display: flex;
}

@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;

  }
  35% {
    width: 100%;
    left: 50%;
  }
  70% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

.header {
  font-family: 'Plaster';
  font-size: 40px;
  position: fixed;
  top: 10%;
  left: 10%;
}

.header a {
  text-decoration: none;
  color: white;
}

.title {
  font-family: 'CabinetBold', Arial;
  font-size: 50px;
}

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro {
  width: max-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.details {
  display: flex;
  flex-direction: column;
  font-size: 30px;
  margin-top: 3.5rem;
  animation: slide-up 0.4s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation-timing-function: ease-in;
}

.details div {
  padding: 0.1rem;
}

.line2 {
  display: flex;
  align-items: center;
}

#hrw {
  height: 29px;
  width: 20px;
  background-color: #1BA94C;
  margin-left: 0.3rem;
}

.profile {
  height: 300px;
  width: 300px;
}

.card {
  display: flex;
  height: 50px;
  width: 200px;
  margin-left: -0.5rem;
}

.card svg {
  position: absolute;
  display: flex;
  width: 60%;
  height: 100%;
  cursor: pointer;
}

.card .social-link1,.card .social-link2,.card .social-link3 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  color: whitesmoke;
  font-size: 24px;
  text-decoration: none;
  border-radius: 50px;
}

.card .social-link1:hover {
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.card .social-link2:hover {
  background-color: #242c34;
  animation: bounce_613 0.4s linear;
}

.card .social-link3:hover {
  background-color: #0a66c2;
  animation: bounce_613 0.4s linear;
}

@media (max-width: 965px) {
  .intro {
    flex-direction: column-reverse;
    text-align: center;
  }
  .card {
    align-self: center;
    height: 40px;
    margin: 0;
    margin-left: 3rem;
  }
}

@media (max-width: 720px) {
  .header {
    display: none;
  }

  .title {
    font-size: 40px;
  }

  .details {
    font-size: 20px;
  }

  #hrw {
    height: 12px;
    width: 12px;
  }

  .details div {
    padding: 0.2rem 0rem
  }

  .card {
    margin-top: 0.1rem;
  }

  .preloader-text {
    flex-direction: column;
  }
}