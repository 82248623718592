body {
  margin: 0;
  background: black;
  /* overflow: hidden; */
  font-family: 'Cabinet', Arial;
  overflow: hidden
}

@font-face {
  font-family: 'Advanium';
  src: local('Advanium'), url(./fonts/mbf-advanium/MBF\ ADVANIUM.ttf) format('truetype');
}

@font-face {
  font-family: 'Cabinet';
  src: local('Cabinet'), url(./fonts/CabinetGrotesk/Fonts/OTF/CabinetGrotesk-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'CabinetThin';
  src: local('Cabinet'), url(./fonts/CabinetGrotesk/Fonts/OTF/CabinetGrotesk-Thin.otf) format('truetype');
}

@font-face {
  font-family: 'CabinetLight';
  src: local('Cabinet'), url(./fonts/CabinetGrotesk/Fonts/OTF/CabinetGrotesk-Light.otf) format('truetype');
}

@font-face {
  font-family: 'CabinetBold';
  src: local('Cabinet'), url(./fonts/CabinetGrotesk/Fonts/OTF/CabinetGrotesk-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'Plaster';
  src: local('Plaster'), url(./fonts/Plaster/Plaster-Regular.ttf) format('truetype');
}
